<template>
  <Modal ref="modal" class="default profile-review-explicit" :settings="settings" :lock="$loading" @close="closeAlert">
    <div class="modal-default-title" slot="header">
      <p class="profile-review-explicit-title">{{ $locale["explicit_content_policy"] }}</p>
    </div>
    <div class="profile-review-explicit-body">
      <div>{{ $locale["i_certify"] + ":" }}</div>
      <Spacer num="2" />
      <ol class="policy-list __top">
        <li class="policy-list-item" v-for="(deny, index) in checkContent" :key="index">
          <span class="dot">{{ index + 1 }}</span>
          <span v-html="$locale[deny]"></span>
        </li>
      </ol>
      <div class="content-text">{{ $locale["content_review_info"] }}</div>
      <div class="content-list">
        <div>{{ $locale["explicit_content_deny"] }}</div>
        <ol class="policy-list">
          <li class="policy-list-item" v-for="(deny, index) in denys" :key="index">
            <span class="dot">{{ index + 1 }}</span>
            <span>{{ $locale[deny] }}</span>
          </li>
        </ol>
      </div>
      <div class="policy-termns">
        {{ $locale["by_click_terms"] }}
      </div>
      <div class="content-options">
        <a class="confirm-button __accept primary" nowrap @click="closeAlert">
          <span>{{ $locale["i_inderstand"] }}</span>
        </a>
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  data: function() {
    return {
      denys: [
        "explicit_content_deny_1",
        "explicit_content_deny_2",
        "explicit_content_deny_3",
        "explicit_content_deny_4",
        "explicit_content_deny_5",
        "explicit_content_sexual_deny_2",
      ],
      checkContent: ["check_content_submit_1", "check_content_submit_2", "check_content_submit_3"],
      settings: {
        opened: true,
        class: "view menu bottom mk_head",
        animation: "fadeIn",
        noclose: true,
      },
    };
  },
  methods: {
    closeAlert: function() {
      this.$store.state.user.profileExplicitContentAlert = false;
    },
  },
  watch: {
    $route: function() {
      this.closeAlert();
    },
  },
};
</script>

<style lang="scss">
.profile-review-explicit {
  display: block;
  &-title {
    padding: 0 $mpadding;
    font-weight: bold;
  }
  &-body {
    display: flex;
    flex-direction: column;
    padding: $mpadding * 2;
  }
  .content-list {
    margin: $mpadding * 2 0 0 0;
    overflow: hidden;
  }
  .content-options {
    margin: $mpadding * 2 0 0 0;
    display: flex;
    justify-content: flex-end;
    position: sticky;
    bottom: $mpadding;
  }
  .policy-list {
    &:not(.__top) {
      margin: $mpadding * 2 0 0 0;
    }
  }
  .policy-list-item {
    margin: 0 0 $mpadding * 1.5 0;
    display: flex;
    .dot {
      margin: 0 $mpadding 0 0;
      color: transparent;
      background: red;
      width: 2px;
    }
  }
  .confirm-button {
    flex: 0.3;
  }
  .policy-termns {
    margin: $mpadding * 2 0 0 0;
  }
}
</style>
